import React, { useEffect, useState } from "react";
import Nav from "./Components/Nav";
import Home from "./Components/tripsccreens/Home";
import { Route, Routes } from "react-router-dom";
import Agents from './Components/Agents';
import Works from "./Components/Works";
import Profile from "./Components/Profile";
// import Footer from "./Components/footer";
import Booking from "./Components/Booking";
import Nav2 from "./Components/Nav2";
import Foot from "./Components/tripsccreens/Foot";
import Aurtrans from "./Components/tripsccreens/Aurtrans";
import Wedding from "./Components/tripsccreens/Wedding";
import Coperate from "./Components/tripsccreens/Coperate";
import Parties from "./Components/tripsccreens/Parties";
import Packages from "./Components/tripsccreens/Packages";
import Vehicles from "./Components/tripsccreens/Vehicles";
import ScrollToTop from "./Components/ScrollToTop";
import Cookies from "./Cookies";
import Navbar from "./Components/Navbar/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import About from "./Components/About/About";
import Footer from "./Components/Footer/Footer";
import Footer2 from "./Components/Footer/Footer2";

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );
  const element = document.documentElement;

  useEffect(() => {
    if (theme === "dark") {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);
  // dark mode end

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="bg-white dark:bg-black dark:text-white text-black overflow-x-hidden">
      <Navbar theme={theme} setTheme={setTheme} />
      <>
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />
          <Route path="Wedding" element={<Wedding />} />
          <Route path="About" element={<About />} />
          <Route path="Aurtrans" element={<Aurtrans />} />
          <Route path="Booking" element={<Booking theme={theme}/>} />
          <Route path="Coperate" element={<Coperate />} />
          <Route path="Parties" element={<Parties />} />
          <Route path="Packages" element={<Packages />} />
          <Route path="Vehicles" element={<Vehicles />} />

        </Routes>
      </>
            <Footer2 />
      <ScrollToTop />
      <Cookies />
      {/* <Foot  /> */}
      {/* <Footer /> */}
      {/* <Home  /> */}
    </div>
  );
}

export default App;
