import React from "react";
import whiteCar from "../../Assets/sss.png";
import car2 from "../../Assets/vvvv.png";
import car3 from "../../Assets/car6.png";

const carList = [
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2000.11.56_d1319352.jpg?alt=media&token=62a1feec-2d2a-4fa8-bc9b-8434c2481bb7",
    // image: car2,
    aosDelay: "500",
  },
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2014.26.27_0c9b7029.jpg?alt=media&token=507e14ee-1a72-41ed-8c02-ccd2a47654eb",
    // image: car2,
    aosDelay: "500",
  },
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2020.16.38_daa8b669.jpg?alt=media&token=757dc894-d62c-433e-972f-a1ee4c8e1e1b",
    // image: car2,
    aosDelay: "500",
  },
 
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2020.21.56_7bbd32bd.jpg?alt=media&token=c76aa4dd-736e-4b09-b35b-5137557d824c",
    // image: car2,
    aosDelay: "500",
  },
 
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2020.22.41_61fabbc0.jpg?alt=media&token=06889e09-f936-41db-b901-f0ccdf26d579",
    // image: car2,
    aosDelay: "500",
  },
 
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2014.26.27_8d44b315.jpg?alt=media&token=c883615b-4bf8-4d5a-bb8f-5bbbaef8a187",
    // image: car2,
    aosDelay: "500",
  },
 
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2014.26.27_7e2fa370.jpg?alt=media&token=67b43ca3-8d31-42af-8aae-7efc353dbcbf",
    // image: car2,
    aosDelay: "500",
  },
 
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2014.26.27_2d59f0e1.jpg?alt=media&token=039c0c7c-3a19-4815-a31b-96830bc74478",
    // image: car2,
    aosDelay: "500",
  },
  
  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2000.14.09_f00f61bc.jpg?alt=media&token=68509ec6-88c8-4d32-9051-b6e55543b21b",
    // image: car2,
    aosDelay: "500",
  },

  {
    name: "",
    // price: 140,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/GODS%20VINEYARD%20EP%2FWhatsApp%20Image%202024-08-15%20at%2014.26.26_9187c83d.jpg?alt=media&token=64ce628a-864f-4bfb-98ed-d8f8a3cd353e",
    // image: car2,
    aosDelay: "500",
  },
 
 
];

const CarList = () => {
  return (
    <div id="ca" className="py-24">
      <div className="container">
        {/* Heading */}
        <h1
          data-aos="fade-up"
          className="text-3xl sm:text-4xl font-bold font-Ubuntu mb-3"
        >
         Outing And Gallery
        </h1>
        <p data-aos="fade-up" aos-delay="400" className="text-sm pb-10">
        Fostering a community of empowered and thriving individuals.
                        </p>
        {/* Car listing */}

        <div className="mt-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
            {carList.map((data) => (
              <div
                data-aos="fade-up"
                data-aos-delay={data.aosDelay}
                className="space-y-2  border-0 border-gray-300 hover:border-primary p-3 text-center rounded-xl relative group"
              >
                <div className="w-full h-[120px]">
                  <img
                    src={data.image}
                    alt=""
                    style={{borderRadius: 30}}
                    className="md:w-full w-96 object-contain lg:mt-0 mt-10 sm:translate-x-8 group-hover:sm:translate-x-16 duration-700"
                  />
                </div>
                <div className="space-y-2 pt-10">
                  <h1 className="text-white font-bold text-2xl">{data.name}</h1>
                  <div className="flex justify-between items-center text-xl font-semibold">
                    {/* <p>${data.price}/Day</p> */}
                    {/* <a href="#">Details</a> */}
                  </div>
                </div>
                <p className="text-xl font-semibold absolute top-0 left-3"></p>
              </div>
            ))}
          </div>
        </div>
        {/* End of car listing */}
        <div className="grid place-items-center mt-8">
          <a href="mailto:p.a.osuala@hotmail.com" data-aos="fade-up" className="button-outline cursor-pointer text-white bg-primary py-3 text-center w-60 rounded-lg">
           Donate Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default CarList;
