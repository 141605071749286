import React, { useState } from 'react'
import {Routes, Route, Link} from 'react-router-dom'
// import Contact from './routes/Contact';
// import Home from './Home';
// import Staffs from './Staffs';
// import Abouts from './Abouts';
// import ISP from './ISP';
// import Podcast from './Podcast';
// import EvenTours from './EventsTours';
// import Book from './Book';
import { motion } from "framer-motion"
// import News from './News';
// import book from './assets/3dhu.png';



// import Home from './routes/Home'
// import Pricing from './routes/Pricing'
// import Training from './routes/Training';
// import About from './routes/About';
// import Event from './routes/Event';
// import TNC from './routes/TNC';
// import Payments from './routes/Payments';

function Cookies() {

  const [Cookie, setCookies] = useState(true);
  const transition = {
    duration: 1,
    type: "spring",
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(!modalIsOpen);
  }
  
  return (
    <>
     

      {
                  Cookie ?

                  <>
        <motion.div
            initial={{ top: "100%" }}
            whileInView={{ top: "60%" }}
            transition={transition}
        className="lg:hidden py-5 z-50 lg:flex grid items-center justify-center text-center fixed bottom-0 right-0 left-0 w-[100%] h-auto bg-[#eee]">
        {/* <div className="font-bold fixed bottom-0 right-0 left-0 w-[100%] h-[10%] bg-[rgba(52,52,52,0.6)]"> */}
         
        
          <p className="text-[#000] text-sm px-4 lg:pt-0 pt-4">
          We use cookies and similar technologies to enhance site navigation, analyze site usage, and assist our public relations team. By clicking “Accept All”, you consent to our use of cookies.
          </p>
          <div 
  onClick={() => {
    setCookies(false)
    // setIsOpen(true)
   }}
                    className=" m-2 cursor-pointer hover:text-[#333]  text-center items-center font-bold h-10 px-5  text-white rounded-lg"
                    style={{borderBottom: 2, borderBottomWidth: 2,  borderBottomColor: '#000'}}

          >

            <p className="self-center mt-2 text-black text-sm text-decoration-line: underline ">


            cancel

            </p>

          </div>
          <div 

onClick={() => {
  setCookies(false)
  // setIsOpen(true)
 }}                    className="m-2 cursor-pointer hover:bg-[#333] text-center items-center font-bold h-10 w-96 drop-shadow-2xl shadow-2xl bg-primary text-white rounded-lg"
          >

            <p className="self-center mt-3 text-xs">


            Accept All Cookies

            </p>
          </div>
        </motion.div>

        <motion.div
            initial={{ top: "100%" }}
            whileInView={{ top: "85%" }}
            transition={transition}
        className="hidden lg:flex  py-5 z-50 lg:flex grid items-center justify-center text-center fixed bottom-0 right-0 left-0 w-[100%] h-auto bg-[#eee]">
        {/* <div className="font-bold fixed bottom-0 right-0 left-0 w-[100%] h-[10%] bg-[rgba(52,52,52,0.6)]"> */}
         
        
          <p className="text-[#000] text-sm px-4 lg:pt-0 pt-4">
          We use cookies and similar technologies to enhance site navigation, analyze site usage, and assist our public relations team. By clicking “Accept All”, you consent to our use of cookies.
          </p>
          <div 
                    onClick={() => {
                       setCookies(false)
                      //  setIsOpen(true)
                      }}

                    className=" m-2 cursor-pointer hover:text-[#333]  text-center items-center font-bold h-10 px-5  text-white rounded-lg"
                    style={{borderBottom: 2, borderBottomWidth: 2,  borderBottomColor: '#000'}}

          >

            <p className="self-center mt-2 text-black text-sm text-decoration-line: underline ">


            cancel

            </p>

          </div>
          <div 
  onClick={() => {
    setCookies(false)
    // setIsOpen(true)
   }}
                    className="m-2 cursor-pointer hover:bg-[#333] text-center items-center font-bold h-10 w-96 drop-shadow-2xl shadow-2xl bg-primary text-white rounded-lg"
          >

            <p className="self-center mt-3 text-xs">


            Accept All Cookies

            </p>
          </div>
        </motion.div>
        </>

        : 
        null
                }

    </>
  );
}

export default Cookies;
