import React from "react";
import AppStoreImg from "../../Assets/website/app_store.png";
import PlayStoreImg from "../../Assets/website/play_store.png";
import pattern from "../../Assets/website/pattern.jpeg";

const bannerImg = {
  backgroundImage: `url(${"https://png.pngtree.com/thumb_back/fh260/background/20220507/pngtree-modern-light-blue-and-white-luxury-abstract-background-image_1319028.jpg"})`,
  // backgroundImage: `url(${pattern})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
};
const AppStoreBanner = () => {
  return (
    <div className="container mt-20">
      <div
        className=" text-black py-10 sm:min-h-[400px] sm:grid sm:place-items-center rounded-xl"
        style={bannerImg}
      >
        <div>
          <div className="space-y-6 max-w-xl mx-auto">
            <h1
              data-aos="fade-up"
              className="text-4xl text-center sm:text-6xl font-bold font-Ubuntu"
            >
              SYSTEMS
            </h1>
            <p data-aos="fade-up" className="text-center sm:px-20 px-10">
            Supporting Primary and Secondary Students with Annual Awards.
                        </p>
            {/* <div
              data-aos="fade-up"
              className="flex flex-wrap justify-center items-center gap-4"
            >
              <a href="#">
                <img
                  src={PlayStoreImg}
                  alt=""
                  className="max-w-[150px] sm:max-w-[120px] md:max-w-[200px]"
                />
              </a>
              <a href="#">
                <img
                  src={AppStoreImg}
                  alt=""
                  className="max-w-[150px] sm:max-w-[120px] md:max-w-[200px]"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStoreBanner;
