import React from "react";
import CarPng from "../../Assets/rolls.png";

const About = () => {
  return (
    <div id="cars" className="dark:bg-dark bg-slate-100 sm:min-h-[600px] sm:grid sm:place-items-center duration-300">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center">
          <div data-aos="slide-right" data-aos-duration="1500" className="pt-5">
            <img
              src={"https://png.pngtree.com/png-vector/20220518/ourmid/pngtree-group-of-raised-arms-of-multicultural-men-and-women-people-holding-png-image_4668718.png"}
              // src={CarPng}
              alt=""
              className="sm:scale-125 sm:-translate-x-11 max-h-[300px] drop-shadow-[2px_10px_6px_rgba(0,0,0,0.50)]"
            />
          </div>
          <div>
            <div className="space-y-5 sm:p-16 pb-6">
              <h1
                data-aos="fade-up"
                className="text-3xl lg:text-4xl font-bold font-Ubuntu"
              >
                About us
              </h1>
              <p data-aos="fade-up" className="leading-8 tracking-wide font-Ubuntu">
              One of the core aims of God's Vineyard Empowerment Programme is to reach out to individuals with disabilities 
              and their families, including those with mental health conditions such as Autism, Down Syndrome, ADHD, 
              sight impairments, and physical challenges, as well as able-bodied individuals from infants to secondary 
              school-aged children, and senior citizens, with the objective of providing food parcels and clothing as needed.
                
                              </p>

              {/* <p data-aos="fade-up" className="font-Ubuntu">
              GOD'S VINEYARD EMPOWERMENT PROGRAMis the premier choice in luxury ground
                transportation for all corporate and executive travelers who
                deserves the best in professional chauffeured transportation
                service
              </p>
              <p data-aos="fade-up font-Ubuntu">
              We pride ourselves in providing the best possible luxury 
              Atlanta limo transfer service to accommodate your business as
               well as your personal needs for luxury chauffeured transportation 
               service in Atlanta & Worldwide in more than 500 cities via our National
                affiliates.
              </p>
              <p data-aos="fade-up font-Ubuntu">
              With just one phone call, experience all the fun and excitement
              </p> */}
              <button data-aos="fade-up" className="button-outline font-Ubuntu">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
