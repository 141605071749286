import React, { useEffect, useState } from 'react'
import { RiWhatsappFill } from 'react-icons/ri';
import { TiArrowSortedUp } from "react-icons/ti"

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const listenToScroll = () => {
        let heightToHideFrom = 100;
        const winScroll = document.body.scroll ||
            document.documentElement.scroll;

        if (winScroll > heightToHideFrom) {
            isVisible &&      // to limit setting state only the first time
                setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])
    return (
        isVisible ? (
            <div 
            className='fixed bottom-5 z-50 cursor-pointer right-5 lg:right-10'>
                {/* <a
                href="https://wa.me/+14049579242"
                    // onClick={scrollToTop}
                    className='text-primary shadow-2xl shadow-black mb-3  h-14 w-14 bg-[#fff] rounded-full flex items-center justify-center cursor-pointer animate-pulse'>
                    <RiWhatsappFill size={25}/>
                </a> */}
                <div
                    onClick={scrollToTop}
                    className='text-primary shadow-2xl shadow-black  h-14 w-14 bg-[#fff] rounded-full flex items-center justify-center cursor-pointer animate-pulse'>
                    <TiArrowSortedUp size={25}/>
                </div>
            </div>
        ) : null

    )
}
